import React from 'react'
import {BrowserRouter , Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Forbusiness from './pages/Forbusiness';
import Forindividual from './pages/Forindividual';
import Solutionecommerce from './pages/Solutionecommerce';
import Solutionpharmacy from './pages/Solutionpharmacy';
import Solutiongrocery from './pages/Solutiongrocery';
import Solutionrestaurants from './pages/Solutionrestaurants';
import Solutionoffices from './pages/Solutionoffices';
import Vacancies from './pages/Vacancies';
import About from './pages/About';
import Clients from './pages/Clients';
import Blogs from './pages/Blogs';
import Contacts from './pages/Contacts';
import Getaquote from './pages/Getaquote';
import Order_Food_Online_Dubai from './blogs/Order_Food_Online_Dubai';
import Top_delivery_companies_in_uae from './blogs/Top_delivery_companies_in_uae';
import Delivery_company_in_abu_dhabi from './blogs/Delivery_company_in_abu_dhabi';
import Express_delivery_company_in_uae from './blogs/Express_delivery_company_in_uae';

const App = () => {
  return (
    
    <BrowserRouter>
    <Routes>

      {/* Main site */}
      <Route path ='/' element={<Home/>}/>
      <Route path ='/services_for_business' element={<Forbusiness/>}/>
      <Route path ='/services_for_individual' element={<Forindividual/>}/>
      <Route path ='/solutions_for_ecommerce' element={<Solutionecommerce/>}/>
      <Route path ='/solutions_for_pharmacy' element={<Solutionpharmacy/>}/>
      <Route path ='/solutions_for_grocery' element={<Solutiongrocery/>}/>
      <Route path ='/solutions_for_restaurants' element={<Solutionrestaurants/>}/>
      <Route path ='/solutions_for_offices' element={<Solutionoffices/>}/>
      <Route path ='/vacancies' element={<Vacancies/>}/>
      <Route path ='/about' element={<About/>}/>
      <Route path ='/clients' element={<Clients/>}/>
      <Route path ='/blogs' element={<Blogs/>}/>
      <Route path ='/contacts' element={<Contacts/>}/>
      <Route path ='/get_a_quote' element={<Getaquote/>}/>
      <Route path ='/order_food_online_dubai' element={<Order_Food_Online_Dubai/>}/>
      <Route path ='/top_delivery_companies_in_uae' element={<Top_delivery_companies_in_uae/>}/>
      <Route path ='/delivery_company_in_abu_dhabi' element={<Delivery_company_in_abu_dhabi/>}/>
      <Route path ='/express-delivery-company-in-uae' element={<Express_delivery_company_in_uae/>}/>

    </Routes>
    </BrowserRouter>



  );
};

export default App