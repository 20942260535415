import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet';

function Vacancies() {
  return (
    <>
     <Helmet>
       <title>#1 Delivery services and Riders in UAE | Vacancies from Delivery Service Provider</title>
       <meta name="description" content="We are one of the leading Delivery services companies in Dubai &amp; Abu Dhabi. We provide the best pickup and Express delivery service in UAE for your business" />
       <meta name="keywords" content="Delivery services companies in Dubai, Delivery company in Abu Dhabi, Best courier company in UAE, Best delivery services in Dubai, Express delivery services in Dubai, Express Delivery Company in UAE" />     
     </Helmet>

    <Header/>
    <section className="page-header page-header-vacancies">
      <div className="page-header-shape" />
      <div className="container">
        <div className="page-header-info">
          <h4>Vacancies</h4>
          <h2>
            Open <span>Vacancies!</span>
          </h2>
          <p>Join Our Team at Reliance Delivery Services</p>
        </div>
      </div>
    </section>
    {/*/.page-header*/}
    <section className="about-section padding">
      <div className="container">
        <div
          className="section-heading text-center mb-40 wow fade-in-bottom"
          data-wow-delay="200ms"
        >
          <h4 className="sub-heading">WORK WITH US</h4>
          <h2>
            Unlock your career potential with <br />
            Reliance Delivery Services
          </h2>
        </div>
        <div className="row">
          <div
            className="col-md-4 text-right wow fade-in-bottom"
            data-wow-delay="300ms"
          >
            <div className="about-img img-1">
              <img src="assets/img/about-1.jpg" alt="img" />
            </div>
          </div>
          <div className="col-md-5 d-flex align-items-center">
            <div
              className="about-content wow fade-in-bottom"
              data-wow-delay="500ms"
            >
              <p>
                At Reliance Delivery Services, we are always on the lookout for
                talented and dedicated individuals to join our growing team. If
                you are passionate about logistics, customer service, and making a
                difference, we invite you to explore our current job openings and
                apply today.
              </p>
              <a href="#" className="read-more">
                <i className="las la-arrow-right" />
              </a>
              <h4>
                <span>
                  A+ Rating <b>Agency</b>
                </span>
              </h4>
            </div>
          </div>
          <div className="col-md-3 wow fade-in-bottom" data-wow-delay="400ms">
            <div className="about-img img-2">
              <img src="assets/img/about-3.jpg" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="service-details bg-grey padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 sm-padding">
            <div className="service-details-info">
              <h4>Why Work with Us?</h4>
              <div className="row">
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Dynamic Work Environment</a>
                      </h3>
                      <a href="#" className="read-more">
                        Be part of a fast-paced and innovative company that values
                        creativity and teamwork.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Career Growth</a>
                      </h3>
                      <a href="#" className="read-more">
                        We offer opportunities for professional development and
                        career advancement, helping you achieve your career goals.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Competitive Benefits</a>
                      </h3>
                      <a href="#" className="read-more">
                        Enjoy a comprehensive benefits package, including health
                        insurance, retirement plans, and paid time off.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Inclusive Culture</a>
                      </h3>
                      <a href="#" className="read-more">
                        Join a diverse and inclusive workplace where everyone’s
                        contributions are valued and respected.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Impactful Work</a>
                      </h3>
                      <a href="#" className="read-more">
                        Make a difference by helping us deliver essential services
                        to businesses and individuals across the region.
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.service-details*/}
    <section className="blog-section faq-page bg-rback padding">
      <div className="map-pattern" />
      <div className="container">
        <div className="row">
          <h4 style={{ color: "white !important" }}>OPEN VACANCIES</h4>
        </div>
        <div>
          <br />
        </div>
        <div className="row">
          <div className="col-lg-12 sm-padding">
            <div className="faq-accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Delivery Drivers{" "}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#faq-accordion"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <i className="las la-hand-point-right" />{" "}
                        <strong> Location:</strong> Various locations
                      </li>
                      <li>
                        <i className="las la-hand-point-right" />{" "}
                        <strong> Responsibilities:</strong> Safely and efficiently
                        deliver packages to customers, maintain delivery logs, and
                        provide excellent customer service.
                      </li>
                      <li>
                        <i className="las la-hand-point-right" />{" "}
                        <strong> Requirements:</strong> Valid driver’s license,
                        clean driving record, and good communication skills.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {" "}
                    Customer Service Representatives{" "}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#faq-accordion"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <i className="las la-hand-point-right" />{" "}
                        <strong> Location:</strong> Dubai
                      </li>
                      <li>
                        <i className="las la-hand-point-right" />{" "}
                        <strong> Responsibilities:</strong> Assist customers with
                        inquiries, track shipments, and resolve issues promptly.
                      </li>
                      <li>
                        <i className="las la-hand-point-right" />{" "}
                        <strong> Requirements:</strong> Strong communication
                        skills, problem-solving abilities, and a customer-focused
                        attitude.
                      </li>
                    </ul>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Blog Grid*/}
          {/*Sidebar*/}
        </div>
        <div className="row">
          <div>
            <br />
          </div>
          <h4 style={{ color: "white" }}>HOW TO APPLY</h4>
          <div style={{ color: "white" }}>
            To apply for any of our current openings, please submit your resume
            and a cover letter to hr@reliancegcc.com. Be sure to specify the
            position you are applying for in the subject line of your email.
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </>
  
  )
}

export default Vacancies