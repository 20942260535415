import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <>
     <Helmet>
       <title>Best courier company in UAE | Best delivery services in Dubai</title>
       <meta name="description" content="As the Best courier company in UAE, we offer affordable, fast &amp; reliable delivery services to our customers. we have expertise to meet your delivery needs." />
       <meta name="keywords" content="Best courier company in UAE, Best delivery services in Dubai, Express delivery services in Dubai, Order food in Dubai Online, online grocery delivery dubai, food delivery dubai, delivery services companies in dubai, Express Delivery Company in UAE" />     
     </Helmet>
  <Header/>
  <section className="about-section padding">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 sm-padding">
          <div className="about-thumb">
            <h2 className="stroke-font">About Us</h2>
            <img src="assets/img/about-3.jpg" alt="img" />
            <div className="about-counter">
              <h3>
                <span className="odometer" data-count={250}>
                  00
                </span>
                +
              </h3>
              <h4>Deliveries every hour</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-6 sm-padding">
          <div className="section-heading mb-20">
            <h4 className="sub-heading">About Reliance Delivery Services</h4>
            <h2>Delivering Excellence, One Package at a Time!</h2>
          </div>
          <div className="about-content">
            <h5>Expand your customer base with our express deliveries!</h5>
            <p style={{ textAlign: "justify !important" }}>
              At Reliance Delivery Services, we are dedicated to providing fast
              and reliable delivery services to our customers. Our team of
              experienced professionals works tirelessly to ensure that every
              delivery is made on time and with the utmost care. We understand
              the importance of timely delivery and strive to exceed our
              customers’ expectations with every order.{" "}
            </p>
            {/* <a href="contact.html" class="default-btn">Get In Touch</a> */}
            <p style={{ textAlign: "justify !important" }}>
              Our commitment to customer satisfaction is at the core of
              everything we do. We are constantly seeking new ways to improve
              our services and make the delivery process as seamless as
              possible. Whether you are an individual or a business, we have the
              expertise and resources to meet your delivery needs.{" "}
            </p>
            <p style={{ textAlign: "justify !important" }}>
              Thank you for choosing Reliance Delivery Services as your trusted
              delivery partner. We look forward to serving you and making your
              delivery experience a positive one.
            </p>
          </div>
        </div>
      </div>
      {/* <div class="row">

          <div class="col-lg-6 col-md-6 padding-15">
              <div class="project-item">                                               
                  <div class="project-content">
                      <h3><a href="#">Order Fulfillment</a></h3>
                      <a href="#" class="read-more">Efficiently manage your inventory, pick and pack orders, and ensure timely dispatch with our comprehensive order fulfillment services.</a>
                  </div>
              </div>
          </div>


          <div class="col-lg-6 col-md-6 padding-15">
              <div class="project-item">                                               
                  <div class="project-content">
                      <h3><a href="#">Order Fulfillment</a></h3>
                      <a href="#" class="read-more">Efficiently manage your inventory, pick and pack orders, and ensure timely dispatch with our comprehensive order fulfillment services.</a>
                  </div>
              </div>
          </div>



      </div> */}
    </div>
  </section>
  <section className="service-details bg-grey padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 padding-15">
          <div className="project-item">
            <div className="project-content">
              <h3>
                <a href="#">Our Mission</a>
              </h3>
              <a href="#" className="read-more">
                Our mission is to revolutionize the delivery experience by
                offering innovative, reliable, and efficient logistics
                solutions. We strive to exceed our customers’ expectations
                through our commitment to excellence and continuous improvement.
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 padding-15">
          <div className="project-item">
            <div className="project-content">
              <h3>
                <a href="#">Our Vision</a>
              </h3>
              <a href="#" className="read-more">
                To be the leading provider of delivery services, known for our
                exceptional customer service, cutting-edge technology, and
                sustainable practices. We aim to set new standards in the
                logistics industry and make a positive impact on the communities
                we serve.
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer/>
</>

  )
}

export default About