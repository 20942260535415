import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <>
     <Helmet>
       <title>Express delivery services in Dubai | Order food in Dubai Online</title>
       <meta name="description" content="Order food in Dubai online from your favorite restaurants. We are Online grocery delivery & food ordering portal offering Express delivery services in Dubai" />
       <meta name="keywords" content="Express delivery services in Dubai, Order food in Dubai Online, online grocery delivery dubai, food delivery dubai, delivery services companies in dubai, best courier company in uae, Express Delivery Company in UAE" />     
     </Helmet>
  <Header/>

    <div className="slider-section">
      <div className="container-fluid no-padding">
        <div className="main-slider slider-2 slider-controler">
          <div className="swiper-wrapper">
            {/* <div class="swiper-slide">
                    <div class="slider-img"><img src="assets/img/slider-1.jpg" alt="img" class="kenburns" style="animation-duration: 12000ms;"></div>
                    <div class="slider-shape-wrap">
                        <div class="slider-shape" data-animation="fade-in-right" data-delay="0.5s"></div>
                        <div class="slider-shape blue" data-animation="fade-in-right" data-delay="0.8s"></div>
                    </div>
                    <div class="container">
                        <div class="slider-content-wrap">
                            <div class="slider-content">
                                <div class="slider-caption medium">
                                    <div class="inner-layer">
                                        <div data-animation="fade-in-bottom" data-delay="0.3s">Smart Business Solution!
                                        </div>
                                    </div>
                                </div>
                                <div class="slider-caption big">
                                    <div class="inner-layer">
                                        <div data-animation="fade-in-bottom" data-delay="0.5s">Modern Digital <br>Agency Solution!
                                        </div>
                                    </div>
                                </div>
                                <div class="slider-btn">
                                    <a href="book-taxi.html" class="default-btn" data-animation="fade-in-bottom" data-delay="0.9s">Get Free Consulting</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="slider-img"><img src="assets/img/slider-4.jpg" alt="img" class="kenburns" style="animation-duration: 12000ms;"></div>
                    <div class="slider-shape-wrap">
                        <div class="slider-shape" data-animation="fade-in-right" data-delay="0.5s"></div>
                        <div class="slider-shape blue" data-animation="fade-in-right" data-delay="0.8s"></div>
                    </div>
                    <div class="container">
                        <div class="slider-content-wrap">
                            <div class="slider-content">
                                <div class="slider-caption medium">
                                    <div class="inner-layer">
                                        <div data-animation="fade-in-bottom" data-delay="0.3s">Smart Business Solution!
                                        </div>
                                    </div>
                                </div>
                                <div class="slider-caption big">
                                    <div class="inner-layer">
                                        <div data-animation="fade-in-bottom" data-delay="0.5s">Modern Digital <br>Agency Solution!
                                        </div>
                                    </div>
                                </div>
                                <div class="slider-btn">
                                    <a href="book-taxi.html" class="default-btn" data-animation="fade-in-bottom" data-delay="0.9s">Get Free Consulting</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            <div className="swiper-slide">
              <div className="slider-img">
                <img
                  src="assets/img/slider-3.jpg"
                  alt="img"
                  className="kenburns"
                  style={{ animationDuration: "12000ms" }}
                />
              </div>
              <div className="slider-shape-wrap">
                <div
                  className="slider-shape"
                  data-animation="fade-in-right"
                  data-delay="0.5s"
                />
                <div
                  className="slider-shape blue"
                  data-animation="fade-in-right"
                  data-delay="0.8s"
                />
              </div>
              <div className="container">
                <div className="slider-content-wrap">
                  <div className="slider-content">
                    <div className="slider-caption medium">
                      <div className="inner-layer">
                        <div data-animation="fade-in-bottom" data-delay="0.3s">
                          Reliance Delivery Services
                        </div>
                      </div>
                    </div>
                    <div className="slider-caption big">
                      <div className="inner-layer">
                        <div data-animation="fade-in-bottom" data-delay="0.5s">
                          Express Delivery <br />
                          Services in Dubai!
                        </div>
                      </div>
                    </div>
                    <div className="slider-btn">
                      <a
                        href="/contacts"
                        className="default-btn"
                        data-animation="fade-in-bottom"
                        data-delay="0.9s"
                      >
                        Get Free Consulting
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination" />
        </div>
      </div>
    </div>
    {/*/.slider-section*/}
    <section className="promo-section bg-grey padding">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-4 col-md-6 sm-padding wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <div className="promo-item">
              <div className="promo-head">
                <div className="promo-icon">
                  <i className="dl dl-award" />
                </div>
                <h3>On Demand Deliveries</h3>
              </div>
              <div className="promo-content">
                <p>
                  Book a single delivery rider for an urgent delivery anytime and
                  avoid any extra charge, , to suit your specific requirements.
                </p>
                <a href="/services_for_business" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 sm-padding wow fade-in-bottom"
            data-wow-delay="300ms"
          >
            <div className="promo-item">
              <div className="promo-head">
                <div className="promo-icon">
                  <i className="dl dl-startup" />
                </div>
                <h3>Customised Solutions</h3>
              </div>
              <div className="promo-content">
                <p>
                  We offer a range of delivery options, including same-day,
                  next-day, and standard delivery, to suit your specific
                  requirements.
                </p>
                <a href="/services_for_individual" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 sm-padding wow fade-in-bottom"
            data-wow-delay="400ms"
          >
            <div className="promo-item">
              <div className="promo-head">
                <div className="promo-icon">
                  <i className="dl dl-team" />
                </div>
                <h3>Dedicated Riders</h3>
              </div>
              <div className="promo-content">
                <p>
                  Hire dedicated drivers with us and provide express delivery
                  solutions to your customers, to suit your specific requirements.
                </p>
                <a href="/services_for_business" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.promo-section*/}
    <section className="about-section padding">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-lg-6 sm-padding wow fade-in-left"
            data-wow-delay="200ms"
          >
            <div className="about-thumb">
              <h2 className="stroke-font">About Us</h2>
              <img src="assets/img/about-3.jpg" alt="img" />
              <div className="about-counter">
                <h3>
                  <span className="odometer" data-count={1}>
                    1
                  </span>
                  M+
                </h3>
                <h4>Packages Delivered</h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 sm-padding wow fade-in-right"
            data-wow-delay="200ms"
          >
            <div className="section-heading mb-20">
              <h4 className="sub-heading">About Our Company</h4>
              <h2>
                Unlock the potential of your business with Reliance Delivery
                Services!
              </h2>
            </div>
            <div className="about-content">
              <h5>Expand your customer base with our express deliveries.</h5>
              <p>
                At Reliance Delivery Services, we are dedicated to providing fast
                and reliable delivery services to our customers. Our team of
                experienced professionals works tirelessly to ensure that every
                delivery is made on time and with the utmost care. We understand
                the importance of timely delivery and strive to exceed our
                customers’ expectations with every order.{" "}
              </p>
              <a href="/about" className="default-btn">
                More About Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.about-section*/}
    <section className="service-section service-2 bg-dark padding">
      <div className="corner-pattern" />
      <div className="corner-pattern bottom" />
      <div className="container">
        <div
          className="section-heading text-center mb-40 wow fade-in-bottom"
          data-wow-delay="200ms"
        >
          <h4 className="sub-heading">Our Services</h4>
          <h2 className="white">
            Best Delivery Company in Dubai! <br />
            Your Last Mile Delivery Partner.
          </h2>
        </div>
        <div className="row">
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <div className="service-card">
              <div className="service-head">
                <h3>Express deliveries</h3>
                <div className="service-icon">
                  <i className="dl dl-startup" />
                </div>
              </div>
              <div className="service-content">
                <p>
                  Completely flexible model | Pay only for the deliveries made for
                  you | Live tracking | 24*7 operational support{" "}
                </p>
                <a href="/services_for_business" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="300ms"
          >
            <div className="service-card">
              <div className="service-head">
                <h3>Dedicated Riders</h3>
                <div className="service-icon">
                  <i className="dl dl-startup" />
                </div>
              </div>
              <div className="service-content">
                <p>
                  Well-trained and dedicated riders | Cost effective delivery
                  platform | Smart phone equipped fleet | Live tracking
                </p>
                <a href="/services_for_business" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="400ms"
          >
            <div className="service-card">
              <div className="service-head">
                <h3>Special Projects</h3>
                <div className="service-icon">
                  <i className="dl dl-startup" />
                </div>
              </div>
              <div className="service-content">
                <p>
                  Adhoc fleet for special projects | Well trained riders | Fixed
                  duration projects | Live tracking | 24*7 support{" "}
                </p>
                <a href="/services_for_business" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="500ms"
          >
            <div className="service-card">
              <div className="service-head">
                <h3>Instant Parcel Pickup</h3>
                <div className="service-icon">
                  <i className="dl dl-startup" />
                </div>
              </div>
              <div className="service-content">
                <p>
                  Reliance Delivery Services gets you hassle-free door-to-door
                  parcel pickup and drop{" "}
                </p>
                <a href="/services_for_individual" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="600ms"
          >
            <div className="service-card">
              <div className="service-head">
                <h3>Online Booking</h3>
                <div className="service-icon">
                  <i className="dl dl-startup" />
                </div>
              </div>
              <div className="service-content">
                <p>
                  Get your parcels &amp; packages delivered with on-demand
                  availability through our portal.{" "}
                </p>
                <a href="/services_for_individual" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="700ms"
          >
            <div className="service-card">
              <div className="service-head">
                <h3>Same-day delivery</h3>
                <div className="service-icon">
                  <i className="dl dl-startup" />
                </div>
              </div>
              <div className="service-content">
                <p>
                  With same-day delivery, get samples and packages delivered to
                  customers.{" "}
                </p>
                <a href="/services_for_individual" className="read-more">
                  Explore Services{" "}
                  <span>
                    <i className="las la-long-arrow-alt-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.service-section*/}
    <section className="why-choose-us padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-heading mb-40">
              <h4 className="sub-heading">Why us?</h4>
              <h2>
                Why choose Reliance <br />
                Delivery Services!
              </h2>
            </div>
            <ul className="feature-lists">
              <li className="feature-item">
                <div className="feature-icon">
                  <i className="dl dl-presentation" />
                </div>
                <h3>Extensive Network</h3>
                <p>
                  Reliance Delivery Services boasts a vast network with numerous
                  offices across multiple countries.
                </p>
                <a href="/contacts" className="read-more">
                  <i className="las la-long-arrow-alt-right" />
                </a>
              </li>
              <li className="feature-item">
                <div className="feature-icon">
                  <i className="dl dl-analysis-1" />
                </div>
                <h3>Global and Domestic</h3>
                <p>
                  We offer both international and domestic logistics services,
                  equipped to handle your supply chain needs.
                </p>
                <a href="/contacts" className="read-more">
                  <i className="las la-long-arrow-alt-right" />
                </a>
              </li>
              <li className="feature-item">
                <div className="feature-icon">
                  <i className="dl dl-success" />
                </div>
                <h3>Customer-Centric</h3>
                <p>
                  Our customer-centric approach ensures that your end-to-end
                  logistics needs are always prioritized.{" "}
                </p>
                <a href="/contacts" className="read-more">
                  <i className="las la-long-arrow-alt-right" />
                </a>
              </li>
              <li className="feature-item">
                <div className="feature-icon">
                  <i className="dl dl-startup" />
                </div>
                <h3>Quality Assurance</h3>
                <p>
                  Committed to offering the best logistics services with a focus
                  on quality and customer satisfaction.
                </p>
                <a href="/contacts" className="read-more">
                  <i className="las la-long-arrow-alt-right" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-6">
                <div className="feature-review">
                  <h2>5.0+</h2>
                  <ul className="ratings">
                    <li>
                      <i className="las la-star" />
                    </li>
                    <li>
                      <i className="las la-star" />
                    </li>
                    <li>
                      <i className="las la-star" />
                    </li>
                    <li>
                      <i className="las la-star" />
                    </li>
                    <li>
                      <i className="las la-star" />
                    </li>
                  </ul>
                  <h3>Customer Reviews</h3>
                  <p>
                    Reliance Delivery Services is praised for its strong focus on
                    teamwork and support, creating a positive and reliable
                    service.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-review">
                  <h2>5.0+</h2>
                  <ul className="ratings">
                    <li>
                      <i className="las la-star" />
                    </li>
                    <li>
                      <i className="las la-star" />
                    </li>
                    <li>
                      <i className="las la-star" />
                    </li>
                    <li>
                      <i className="las la-star" />
                    </li>
                    <li>
                      <i className="las la-star" />
                    </li>
                  </ul>
                  <h3>Business Reviews</h3>
                  <p>
                    Reliance Delivery Services is highly regarded for its
                    excellent work culture, strong teamwork, and supportive
                    management.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-img img-1">
                  <img src="assets/img/about-1.jpg" alt="img" />
                </div>
                <div className="experience-item">
                  <img src="assets/img/award-symbol.png" alt="img" />
                  <h3>
                    20+ Years <br />
                    Experience!
                  </h3>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature-img img-2">
                  <img src="assets/img/feature-2.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.why-choose-us*/}
    <section className="counter-section bg-grey section-2 padding">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-3 col-md-6 sm-padding wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <div className="counter-item">
              <div className="counter-icon">
                <i className="dl dl-startup" />
              </div>
              <div className="counter-content">
                <h3>
                  <span className="odometer" data-count={1}>
                    00
                  </span>
                  M+
                </h3>
                <h4>Packages Delivered</h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 sm-padding wow fade-in-bottom"
            data-wow-delay="300ms"
          >
            <div className="counter-item">
              <div className="counter-icon">
                <i className="dl dl-team" />
              </div>
              <div className="counter-content">
                <h3>
                  <span className="odometer" data-count={800}>
                    00
                  </span>
                  +
                </h3>
                <h4>Satisfied Clients</h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 sm-padding wow fade-in-bottom"
            data-wow-delay="400ms"
          >
            <div className="counter-item">
              <div className="counter-icon">
                <i className="dl dl-dollar" />
              </div>
              <div className="counter-content">
                <h3>
                  <span className="odometer" data-count={2}>
                    00
                  </span>
                  K+
                </h3>
                <h4>Expert Teams</h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 sm-padding wow fade-in-bottom"
            data-wow-delay="500ms"
          >
            <div className="counter-item">
              <div className="counter-icon">
                <i className="dl dl-award" />
              </div>
              <div className="counter-content">
                <h3>
                  <span className="odometer" data-count={10}>
                    00
                  </span>
                  +
                </h3>
                <h4>Award Won</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.counter-section*/}
    <section className="video-section">
      <div className="shape-wrap">
        <div className="shape" />
        <div className="shape red" />
      </div>
      <div className="overlay" />
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-lg-6 col-md-8 sm-padding wow fade-in-left"
            data-wow-delay="200ms"
          >
            <div className="section-heading mb-40">
              <h4 className="sub-heading">Get Extra Benefits</h4>
              <h2 className="white">
                Evolve into full potential <br />
                with Reliance Delivery Services!
              </h2>
              <a href="/contacts" className="default-btn">
                Contact today
              </a>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-4 sm-padding wow fade-in-right"
            data-wow-delay="300ms"
          >
            <div className="play-btn-wrap">
              <a
                className="play-btn dl-video-popup"
                data-vbtype="video"
                href="https://youtu.be/cob0HCqVB_A?controls=0"
              >
                <span className="play-icon">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.video-section*/}
    <section className="sponsor-section padding bg-grey">
      <div className="corner-pattern" />
      <div className="corner-pattern bottom" />
      <div className="container">
        <div className="section-heading-wrap mb-40">
          <div className="section-heading">
            <h4 className="sub-heading">Join with us</h4>
            <h2>
              Successful Companies <br />
              Partnered with us!
            </h2>
          </div>
          <a href="/contacts" className="default-btn">
            Join With Us Now
          </a>
          <div className="arrow-img" />
        </div>
        <div className="outside-spacing sponsor-2">
          <div className="sponsor-carousel">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="assets/img/sponsor01.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor02.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor03.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor04.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor05.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor06.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor07.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor08.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor09.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor10.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor11.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor12.png" alt="client" />
              </div>
              <div className="swiper-slide">
                <img src="assets/img/sponsor13.png" alt="client" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.sponsor-section*/}
    <section className="blog-section padding">
      <div className="container">
        <div
          className="section-heading text-center mb-40 wow fade-in-bottom"
          data-wow-delay="200ms"
        >
          <h4 className="sub-heading">Latest Article</h4>
          <h2>
            Tips and Advice <br />
            From Blog
          </h2>
        </div>
        <div className="row grid-post">
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="200ms"
          >
            <div className="post-card">
              <div className="post-thumb">
                <img src="assets/img/post-1.jpg" alt="post" />
              </div>
              <div className="post-content-wrap">
                <ul className="post-meta">
                  <li>
                    <i className="las la-calendar" />
                    Jan 01 2022
                  </li>
                  <li>
                    <i className="las la-tags" />
                    <a href="#">foodonline</a>
                  </li>
                </ul>
                <div className="post-content">
                  <h3>
                    <a href="/order_food_online_dubai" className="hover">
                    Order Your Food Online in Dubai.
                    </a>
                  </h3>
                  <p>
                  A diverse culinary scene offers a wide array of delicious cuisines that can be conveniently enjoyed. Whether you're a resident or a visitor, ordering food online in Dubai is a hassle-free way to satisfy your cravings.
                  </p>
                  <a href="/order_food_online_dubai" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="300ms"
          >
            <div className="post-card">
              <div className="post-thumb">
                <img src="assets/img/post-2.jpg" alt="post" />
              </div>
              <div className="post-content-wrap">
                <ul className="post-meta">
                  <li>
                    <i className="las la-calendar" />
                    Jan 01 2022
                  </li>
                  <li>
                    <i className="las la-tags" />
                    <a href="#">delivery</a>
                  </li>
                </ul>
                <div className="post-content">
                  <h3>
                    <a href="/delivery_company_in_abu_dhabi">
                    Delivery Company in Abu Dhabi, UAE.
                    </a>
                  </h3>
                  <p>
                  Transporting food products through delivery companies in Abu Dhabi has become vital in today's fast-paced world. With the growing demand for convenience and accessibility, the food industry has a significant shift.
                  </p>
                  <a href="/delivery_company_in_abu_dhabi" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 padding-15 wow fade-in-bottom"
            data-wow-delay="400ms"
          >
            <div className="post-card">
              <div className="post-thumb">
                <img src="assets/img/post-3.jpg" alt="post" />
              </div>
              <div className="post-content-wrap">
                <ul className="post-meta">
                  <li>
                    <i className="las la-calendar" />
                    Jan 01 2022
                  </li>
                  <li>
                    <i className="las la-tags" />
                    <a href="#">expressdelivery</a>
                  </li>
                </ul>
                <div className="post-content">
                  <h3>
                    <a href="/express-delivery-company-in-uae">
                    Express Delivery Company in UAE.
                    </a>
                  </h3>
                  <p>
                  The popularity of home grocery delivery services can be attributed to several key factors. Over the years, these services have gained widespread acceptance as the convenience of ordering goods online became more prevalent.
                  </p>
                  <a href="/express-delivery-company-in-uae" className="read-more">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*/.blog-section*/}
    {/* <section className="testimonial-section-2 bg-grey padding">
      <div className="container">
        <div className="section-heading-wrap mb-40">
          <div className="section-heading">
            <h4 className="sub-heading">Clients Testimonials</h4>
            <h2>
              What our customers tell <br />
              about our Business!
            </h2>
          </div>
          <div className="carousel-navigation">
            <button className="carousel-button-prev">
              <i className="las la-long-arrow-alt-left" />
            </button>
            <button className="carousel-button-next">
              <i className="las la-long-arrow-alt-right" />
            </button>
          </div>
        </div>
        <div className="testimonial-carousel-wrapper">
          <div className="testimonial-carousel-2 testimonial-card">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testi-item">
                  <div className="quote-icon">
                    <i className="las la-quote-right" />
                  </div>
                  <p>
                    Reliance Delivery Services is a trusted delivery partner for
                    providing assistants. Initially their main objective was to
                    ensure service they provide these people are loyal to their
                    industry.{" "}
                  </p>
                  <div className="testi-author">
                    <div className="author-thumb">
                      <img src="assets/img/comment-1.png" alt="author" />
                    </div>
                    <div className="author-info">
                      <h3>Eredrik Johanson</h3>
                      <h4>Financial .INC</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testi-item">
                  <div className="quote-icon">
                    <i className="las la-quote-right" />
                  </div>
                  <p>
                    Reliance Delivery Services is a trusted delivery partner for
                    providing assistants. Initially their main objective was to
                    ensure service they provide these people are loyal to their
                    industry.{" "}
                  </p>
                  <div className="testi-author">
                    <div className="author-thumb">
                      <img src="assets/img/comment-2.png" alt="author" />
                    </div>
                    <div className="author-info">
                      <div className="author-info">
                        <h3>Félix Lengyel</h3>
                        <h4>Corporate .INC</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="testi-item">
                  <div className="quote-icon">
                    <i className="las la-quote-right" />
                  </div>
                  <p>
                    Reliance Delivery Services is a trusted delivery partner for
                    providing assistants. Initially their main objective was to
                    ensure service they provide these people are loyal to their
                    industry.{" "}
                  </p>
                  <div className="testi-author">
                    <div className="author-thumb">
                      <img src="assets/img/comment-3.png" alt="author" />
                    </div>
                    <div className="author-info">
                      <h3>Brandon Larned</h3>
                      <h4>Marketing .INC</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    {/*/.testimonial-section*/}
   
   <Footer/>

  </>
  )
}

export default Home