import React from 'react'

function Footer() {
  return (
    <>
     <footer className="footer-wrapper">
      <div className="map-pattern light" />
      <div className="shape-pattern" />
      <div className="container">
        {/* <div class="footer-top">
            <div>
                <h3>Subscribe to Get Recent News!</h3>
            </div>
            <form action="#" class="subscribe-form">
                <div class="mc-fields">
                    <input class="form-control" type="email" name="EMAIL" placeholder="Your Email" required="">
                    <input type="hidden" name="action" value="mailchimpsubscribe">
                    <button class="submit">Subscribe Now</button>
                </div>
                <div class="clearfix"></div>
                <div id="subscribe-result">
                    <div class="subscription-success"></div>
                    <div class="subscription-error"></div>
                </div>
            </form>
        </div> */}
        <div className="row footer-widgets footer-2">
          <div className="col-lg-3 col-md-6 footer-widget sm-padding">
            <a href="/" className="footer-logo">
              <img src="assets/img/logo-light.png" alt="logo" />
            </a>
            <p>
              Enabling you with live and reliable end to end delivery services for
              your hyperlocal and last mile deliveries.
            </p>
            <div className="social-links">
              <a href="https://www.facebook.com/reliancegcc">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="https://twitter.com/reliancegcc">
                <i className="fab fa-x" />
              </a>
              <a href="https://www.instagram.com/reliancegcc/">
                <i className="fab fa-instagram" />
              </a>
              <a href="https://www.linkedin.com/company/reliancegcc">
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 offset-lg-1 col-md-6 footer-widget sm-padding">
            <h3 className="widget-title">Company</h3>
            <ul>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/contacts">Clients</a>
              </li>
              <li>
                <a href="/vacancies">Vacancies</a>
              </li>
              <li>
                <a href="/blogs">Our Blog</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-widget sm-padding">
            <h3 className="widget-title">Contact</h3>
            <ul>
              <li>Dubai, United Arab Emirates</li>
              <li>Mon-Sat:10.00 am to 7.00pm</li>
              <li>
                +971 423 23 221 <br />
                +971 56 227 6992 <br/>
                info@reliancegcc.com
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 footer-widget sm-padding">
            <h3 className="widget-title">Need a Rider? Have an ecommerce?</h3>
            <a href="/contacts" className="contact-btn">
              Contact us
            </a>
          </div>
        </div>
        {/* /.footer-widgets */}
        <div className="footer-bottom">
          <div className="copyright-text">
            © <span id="currentYear" /> Reliance Delivery Services, All Rights
            Reserved. Powered By{" "}
            <a href="https://daddycool.tech" target="_blank">
              DC
            </a>
          </div>
          <ul>
            <li>
              <a href="/get_a_quote">Get a Quote</a>
            </li>
          </ul>
        </div>
        {/* /.footer-bottom */}
      </div>
      {/* /.container */}
    </footer>
    {/* /.footer-wrapper */}
    <div id="scrollup">
      <button id="scroll-top" className="scroll-to-top">
        <i className="las la-arrow-up" />
      </button>
    </div>
    {/*scrollup*/}
    </>
  )
}

export default Footer