import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

function Blogs() {
  return (
    <>
      <Helmet>
       <title>Blog from Best courier company in UAE | Best delivery services in Dubai</title>
       <meta name="description" content="As the Best courier company in UAE, we offer affordable, fast &amp; reliable delivery services to
    our customers. we have expertise to meet your delivery needs." />
       <meta name="keywords" content="Best courier company in UAE, Best delivery services in Dubai, Express delivery services in Dubai,
    Order food in Dubai Online, online grocery delivery dubai, food delivery dubai, delivery services companies in dubai,
    Express Delivery Company in UAE" />     
     </Helmet>
    <Header/>
    <section className="page-header page-header-blog">
      <div className="page-header-shape" />
      <div className="container">
        <div className="page-header-info">
          <h4>RDS</h4>
          <h2>
            <span>Blogs!</span>
          </h2>
          <p>
            Your One-Stop Destination for Stories <br />
            Behind Every Delivery.
          </p>
        </div>
      </div>
    </section>
    {/*/.page-header*/}
    <section className="blog-section blog-page bg-grey padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 sm-padding">
            <div className="row grid-post">
              <div className="col-md-6 padding-15">
                <div className="post-card">
                  <div className="post-thumb">
                    <img src="assets/img/blog/1.jpg" alt="post" />
                  </div>
                  <div className="post-content-wrap">
                    <ul className="post-meta">
                      <li>
                        <i className="las la-calendar" />
                        11-Mar-2024
                      </li>
                      <li>
                        <i className="las la-tags" />
                        <a href="#">General</a>
                      </li>
                    </ul>
                    <div className="post-content">
                      <h3>
                        <a href="/order_food_online_dubai" className="hover">
                          Order Your Food Online in Dubai.
                        </a>
                      </h3>
                      <p>
                        A diverse culinary scene offers a wide array of delicious
                        cuisines that can be conveniently enjoyed. Whether you're
                        a resident or a visitor, ordering food online in Dubai is
                        a hassle-free way to satisfy your cravings.
                      </p>
                      <a href="/order_food_online_dubai" className="read-more">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 padding-15">
                <div className="post-card">
                  <div className="post-thumb">
                    <img src="assets/img/blog/2.jpg" alt="post" />
                  </div>
                  <div className="post-content-wrap">
                    <ul className="post-meta">
                      <li>
                        <i className="las la-calendar" />
                        11-Mar-2024
                      </li>
                      <li>
                        <i className="las la-tags" />
                        <a href="#">General</a>
                      </li>
                    </ul>
                    <div className="post-content">
                      <h3>
                        <a href="/top_delivery_companies_in_uae">
                          Top Delivery Companies in UAE.
                        </a>
                      </h3>
                      <p>
                        we outline the key attributes of a reputable local
                        delivery company so that you don't have to wonder! Above
                        all else, you must have faith in your local delivery
                        service. If they can't be relied upon to fulfill their
                        assigned tasks.
                      </p>
                      <a href="/top_delivery_companies_in_uae" className="read-more">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 padding-15">
                <div className="post-card">
                  <div className="post-thumb">
                    <img src="assets/img/blog/3.jpg" alt="post" />
                  </div>
                  <div className="post-content-wrap">
                    <ul className="post-meta">
                      <li>
                        <i className="las la-calendar" />
                        10-Mar-2024
                      </li>
                      <li>
                        <i className="las la-tags" />
                        <a href="#">General</a>
                      </li>
                    </ul>
                    <div className="post-content">
                      <h3>
                        <a href="/delivery_company_in_abu_dhabi">
                          Delivery Company in Abu Dhabi, UAE.
                        </a>
                      </h3>
                      <p>
                        Transporting food products through delivery companies in
                        Abu Dhabi has become vital in today's fast-paced world.
                        With the growing demand for convenience and accessibility,
                        the food industry has a significant shift.
                      </p>
                      <a href="/delivery_company_in_abu_dhabi" className="read-more">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 padding-15">
                <div className="post-card">
                  <div className="post-thumb">
                    <img src="assets/img/blog/4.jpg" alt="post" />
                  </div>
                  <div className="post-content-wrap">
                    <ul className="post-meta">
                      <li>
                        <i className="las la-calendar" />
                        10-Mar-2024
                      </li>
                      <li>
                        <i className="las la-tags" />
                        <a href="#">General</a>
                      </li>
                    </ul>
                    <div className="post-content">
                      <h3>
                        <a href="/express-delivery-company-in-uae">
                          Express Delivery Company in UAE.
                        </a>
                      </h3>
                      <p>
                        The popularity of home grocery delivery services can be
                        attributed to several key factors. Over the years, these
                        services have gained widespread acceptance as the
                        convenience of ordering goods online became more
                        prevalent.
                      </p>
                      <a href="/express-delivery-company-in-uae" className="read-more">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <ul class="pagination-wrap text-left mt-30">
                    <li><a href="#"><i class="las la-arrow-left"></i></a></li>
                    <li><a href="#">1</a></li>
                    <li><a href="#" class="active">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#"><i class="las la-arrow-right"></i></a></li>
                </ul> */}
            {/*Pagination*/}
          </div>
          {/*Blog Grid*/}
          <div className="col-lg-4 sm-padding">
            {/* <div class="sidebar-widget">
                    <form action="" class="search-form">
                        <input type="text" class="form-control" placeholder="Search">
                        <button class="search-btn" type="button"><i class="fa fa-search"></i></button>
                    </form>
                </div> */}
            {/*Search Form*/}
            <div className="sidebar-widget">
              <div className="widget-title">
                <h3>Categories</h3>
              </div>
              <ul className="category-list">
                <li>
                  <a href="#">General</a>
                  <span>6</span>
                </li>
              </ul>
            </div>
            {/*Categories*/}
            <div className="sidebar-widget">
              <div className="widget-title">
                <h3>Tags</h3>
              </div>
              <ul className="tags">
                <li>
                  <a href="#">business</a>
                </li>
                <li>
                  <a href="#">marketing</a>
                </li>
                <li>
                  <a href="#">startup</a>
                </li>
                <li>
                  <a href="#">delivery</a>
                </li>
                <li>
                  <a href="#">consulting</a>
                </li>
                <li>
                  <a href="#">strategy</a>
                </li>
                <li>
                  <a href="#">development</a>
                </li>
                <li>
                  <a href="#">online food delivery</a>
                </li>
                <li>
                  <a href="#">ecommerce</a>
                </li>
              </ul>
            </div>
            {/*Tags*/}
          </div>
          {/*Sidebar*/}
        </div>
      </div>
    </section>
    <Footer/>
  </>
  
  )
}

export default Blogs