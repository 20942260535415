import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Solutionsidebar from '../Components/Solutionsidebar'
import { Helmet } from 'react-helmet';

function Solutiongrocery() {
  return (
    <>
     <Helmet>
       <title>#1 Best Delivery services companies in UAE | Grocery Delivery Solutions</title>
       <meta name="description" content="We are one of the leading Delivery services companies in Dubai &amp; Abu Dhabi. We provide the best pickup and Express delivery service in UAE for your business" />
       <meta name="keywords" content="Delivery services companies in Dubai, Delivery company in Abu Dhabi, Best courier company in UAE, Best delivery services in Dubai, Express delivery services in Dubai, Express Delivery Company in UAE" />     
     </Helmet>
    <Header/>
    <section className="page-header page-header-sol-grocery">
      <div className="page-header-shape" />
      <div className="container">
        <div className="page-header-info">
          <h4>Solutions</h4>
          <h2>
            Solutions for <br /> <span>Grocery Business!</span>
          </h2>
          <p>Efficient and Fast Delivery Solutions for Your Grocery Store</p>
        </div>
      </div>
    </section>
    {/*/.page-header*/}
    <section className="service-details bg-grey padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 sm-padding">
            <div className="service-details-info">
              {/* <img src="assets/img/post-2.jpg" alt="img"> */}
              <h2>Solutions for Grocery Business</h2>
              <h4>
                Reliable, Efficient, and Fast Delivery Solutions for Grocery
                Store.
              </h4>
              <p>
                At Reliance Delivery Services, we understand the importance of
                timely and reliable delivery in the grocery industry. Our
                specialized solutions are designed to ensure that your grocery
                business operates smoothly, keeping your shelves stocked and your
                customers satisfied.
              </p>
              <div>
                <br />
              </div>
              <h4>Customised solutions for your business</h4>
              <h6>Excellent Customer service and operations support</h6>
              <div className="row">
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Same-Day Delivery</a>
                      </h3>
                      <a href="#" className="read-more">
                        Ensure that your customers receive their groceries quickly
                        with our same-day delivery service.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Scheduled Deliveries </a>
                      </h3>
                      <a href="#" className="read-more">
                        {" "}
                        Plan your deliveries in advance with our scheduled
                        delivery options, ensuring timely restocking of your
                        inventory.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Bulk Shipments</a>
                      </h3>
                      <a href="#" className="read-more">
                        Manage large orders efficiently with our bulk shipment
                        services, perfect for high-demand periods.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Cold Chain Logistics</a>
                      </h3>
                      <a href="#" className="read-more">
                        Maintain the freshness and quality of perishable items
                        with our specialized cold chain logistics solutions.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Returns Management</a>
                      </h3>
                      <a href="#" className="read-more">
                        Simplify the returns process with our hassle-free returns
                        management service, ensuring customer satisfaction and
                        compliance.
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <br />
              </div>
              <div>
                {/* <img src="assets/img/post-1.jpg" style="height:100% !important;" alt="img"> */}
                <div>
                  <h4>
                    Why choose Reliance Delivery Services for Your Grocery Store
                    Business?
                  </h4>
                  <p>Everything your business needs is already here!</p>
                  <ul className="service-icon-list">
                    <li>
                      <i className="las la-hand-point-right" />
                      Fast and Reliable Deliveries
                    </li>
                    <p>
                      Our extensive logistics network ensures that your groceries
                      are delivered quickly and reliably, minimizing stockouts and
                      ensuring fresh products.
                    </p>
                    <li>
                      <i className="las la-hand-point-right" />
                      Secure Handling
                    </li>
                    <p>
                      We prioritize the safety and quality of your products with
                      secure packaging and handling, ensuring they reach your
                      customers in perfect condition.
                    </p>
                    <li>
                      <i className="las la-hand-point-right" />
                      Real-Time Tracking
                    </li>
                    <p>
                      Keep your customers informed with real-time tracking
                      updates, providing transparency and peace of mind from
                      dispatch to delivery.
                    </p>
                    <li>
                      <i className="las la-hand-point-right" />
                      Flexible Solutions
                    </li>
                    <p>
                      Whether you need same-day delivery, scheduled deliveries, or
                      bulk shipments, our flexible solutions can be customized to
                      meet your specific needs.
                    </p>
                    <li>
                      <i className="las la-hand-point-right" />
                      Dedicated Support
                    </li>
                    <p>
                      Our customer support team is available 24/7 to assist with
                      any queries or special requests, ensuring a seamless
                      delivery experience.
                    </p>
                  </ul>
                </div>
              </div>
              <div>
                <br />
              </div>
              <h4>Benefits for Your Ecommerce Business</h4>
              <div className="row">
                <div className="col-lg-12 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Enhanced Customer Satisfaction</a>
                      </h3>
                      <a href="#" className="read-more">
                        Provide your customers with fast, reliable, and
                        transparent delivery services, boosting their satisfaction
                        and loyalty.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Operational Efficiency </a>
                      </h3>
                      <a href="#" className="read-more">
                        Streamline your delivery processes and reduce operational
                        burdens with our efficient logistics solutions.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Cost Savings</a>
                      </h3>
                      <a href="#" className="read-more">
                        Optimize your delivery costs with our competitive rates
                        and efficient logistics network.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 padding-15">
                  <div className="project-item">
                    <div className="project-content">
                      <h3>
                        <a href="#">Scalability</a>
                      </h3>
                      <a href="#" className="read-more">
                        Our solutions are designed to grow with your business,
                        providing the flexibility you need to adapt to changing
                        demands.
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <br />
              </div>
              <h4>Partner with us!</h4>
              <p>
                Join the many grocery businesses that trust Reliance Delivery
                Services for their delivery needs. Contact us today to learn more
                about how our solutions can help your grocery business thrive.
              </p>
            </div>
          </div>
          <div className="col-lg-4 sm-padding">
          <Solutionsidebar/>
            <div className="sidebar-widget banner">
              <div className="banner-info">
                <a href="/">
                  <img
                    className="logo"
                    src="assets/img/logo-light.png"
                    alt="logo"
                  />
                </a>
                <h3>
                  Customised Solutions <span>Years of Expertise!</span>
                </h3>
                <p>
                  Contact us today to learn more about how our solutions can help
                  your business thrive.
                </p>
                {/* <h2 class="discount"><span>-60%</span> Off Today!</h2> */}
                <a href="/contacts" className="default-btn">
                  Contact us Today!
                </a>
              </div>
            </div>
            {/*Tags*/}
          </div>
        </div>
      </div>
    </section>
    {/*/.service-details*/}
    <Footer/>
  </>
  
  )
}

export default Solutiongrocery