import React from 'react'

function Solutionsidebar() {
  return (
    <>
    <div className="service-sidebar sidebar-widget">
              <div className="widget-title service-widget">
                <h3>Customised Solutions</h3>
              </div>
              <ul>
                <li>
                  <a href="/solutions_for_ecommerce">For Ecommerce</a>
                  <i className="las la-arrow-right" />
                </li>
                <li>
                  <a href="/solutions_for_pharmacy">For Pharmacy</a>
                  <i className="las la-arrow-right" />
                </li>
                <li>
                  <a href="/solutions_for_grocery">For Grocery</a>
                  <i className="las la-arrow-right" />
                </li>
                <li>
                  <a href="/solutions_for_restaurants">For Restaurants</a>
                  <i className="las la-arrow-right" />
                </li>
                <li>
                  <a href="/solutions_for_offices">For Office</a>
                  <i className="las la-arrow-right" />
                </li>
              </ul>
            </div>
    </>
  )
}

export default Solutionsidebar