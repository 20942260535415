import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet';

function Getaquote() {


    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_e4c9r8l', 'template_6pynwng', form.current, 'D-LGoT-xcLR9Qp1Se')
        .then((result) => {
            console.log(result.text);
            alert('Message sent successfully!');
        }, (error) => {
            console.log(error.text);
            alert('Failed to send the message, please try again.');
        });

    };




  return (
    <>
      <Helmet>
       <title>Express Delivery Company in Dubai | Reliance Delivery Services</title>
       <meta name="description" content="Looking for Top Delivery Companies in UAE? We are dedicated Express Delivery Company in UAE. Offer affordable delivery service to customers with utmost care" />
       <meta name="keywords" content="Express Delivery Company in UAE, Best delivery services in Dubai, Express delivery service UAE, Delivery services companies in Dubai, Delivery company in Abu Dhabi, Express delivery services in Dubai" />     
     </Helmet>
    <Header/>
  <section className="page-header page-header-gaq">
    <div className="page-header-shape" />
    <div className="container">
      <div className="page-header-info">
        <h4>RDS</h4>
        <h2>
          Get <span>a Quote!</span>
        </h2>
        <p>Fast, Easy, and Accurate Quotes for Your Delivery Needs</p>
      </div>
    </div>
  </section>
  {/*/.page-header*/}
  <section className="contact-section bd-bottom padding">
    <div className="map-pattern" />
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="contact-details-wrap">
            <div className="section-heading mb-30">
              <h4 className="sub-heading">Get In Touch</h4>
              <h2>Have Any Questions?</h2>
              <p>
                At Reliance Delivery Services, we value our customers and are
                committed to providing exceptional service. Whether you have a
                question, need assistance, or want to learn more about our
                services, we’re here to help.
              </p>
            </div>
            <ul className="contact-details">
              <li>
                <i className="las la-map-marked-alt" />
                Dubai National Insurance Building - Floor #503 - 5,
                <br /> Port Saeed, Dubai, United Arab Emirates
              </li>
              <li>
                <i className="las la-envelope-open" />
                info@reliancegcc.com
              </li>
              <li>
                <i className="las la-phone-volume" />
                +971 423-23-221{" "}<br/>+971 56 227 6992
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="contact-form">
            <form
              ref={form} onSubmit={sendEmail}
              className="form-horizontal"
            >
              <div className="section-heading mb-30">
                <h4 className="sub-heading">Get a Quote from RDS!</h4>
                <h2>Get a Quote!</h2>
              </div>
              <div className="contact-form-group">
                <div className="form-field">
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    className="form-control"
                    placeholder="First Name"
                    required=""
                  />
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    className="form-control"
                    placeholder="Last Name"
                    required=""
                  />
                </div>
                <div className="form-field">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    required=""
                  />
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Phone Number"
                    required=""
                  />
                </div>
                <div className="form-field message">
                  <select name="service" id="service" className="form-control">
                    <option value="Choose Service">Choose Service</option>
                    <option value="Dedicated Riders">Dedicated Riders</option>
                    <option value="On demand express deliveries">
                      On demand express deliveries
                    </option>
                    <option value="Same-day delivery">Same-day delivery</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <div className="form-field message">
                  <textarea
                    id="message"
                    name="message"
                    cols={30}
                    rows={4}
                    className="form-control"
                    placeholder="Message"
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="form-field submit-btn">
                  <button id="submit" className="default-btn" type="submit">
                    Get a Quote
                  </button>
                </div>
              </div>
              <div id="form-messages" className="alert" role="alert" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/.contact-section*/}
  <Footer/>
</>

  )
}

export default Getaquote