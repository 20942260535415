import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

function Forbusiness() {
  return (
    <>
     <Helmet>
       <title>Delivery services companies in Dubai | Delivery company in Abu Dhabi</title>
       <meta name="description" content="We are one of the leading Delivery services companies in Dubai &amp; Abu Dhabi. We provide the best pickup and Express delivery service in UAE for your business" />
       <meta name="keywords" content="Delivery services companies in Dubai, Delivery company in Abu Dhabi, Best courier company in UAE, Best delivery services in Dubai, Express delivery services in Dubai, Express Delivery Company in UAE" />     
     </Helmet>
      <Header/>
  <section className="page-header page-header-servicebusiness">
    <div className="page-header-shape" />
    <div className="container">
      <div className="page-header-info">
        <h4>Services</h4>
        <h2>
          For Business <br /> <span>Express Delivery for Business!</span>
        </h2>
        <p>
          Completely flexible model where you pay only <br />
          for the deliveries.{" "}
        </p>
      </div>
    </div>
  </section>
  {/*/.page-header*/}
  <section className="service-details bg-grey padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 sm-padding">
          <div className="service-details-info">
            {/* <img src="assets/img/post-2.jpg" alt="img"> */}
            <h2>Express Delivery for Business</h2>
            <h4>
              Fast, Reliable, and Efficient Delivery Solutions for Your Business
              Needs
            </h4>
            <p>
              At Reliance Delivery Services, we understand that in the
              fast-paced world of business, time is of the essence. That's why
              we offer Express Delivery for Business, a service designed to meet
              your urgent delivery requirements with speed and precision.
            </p>
            <div>
              <br />
            </div>
            <h4>Customised solutions for your business</h4>
            <h6>Excellent Customer service and operations support</h6>
            <ul className="service-features">
              <li>
                <div>
                  <i className="las la-shipping-fast" />
                </div>
                <div>
                  <h3>On demand express deliveries</h3>
                  <p>
                    Completely flexible model | Pay only for the deliveries made
                    for you | Live tracking | 24*7 operational support.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <i className="las la-pencil-ruler" />
                </div>
                <div>
                  <h3>Special Projects</h3>
                  <p>
                    Adhoc fleet for special projects | Well trained riders |
                    Fixed duration projects | Live tracking | 24*7 operational
                    support!
                  </p>
                </div>
              </li>
              {/* <li>
                          <div><i class="las la-object-ungroup"></i></div>
                          <div>
                              <h3>Visual Designing</h3>
                              <p>Everything your taxi business needs is already here!</p>
                          </div>
                      </li> */}
              <li>
                <div>
                  <i className="las la-user-astronaut" />
                </div>
                <div>
                  <h3>Dedicated Riders</h3>
                  <p>
                    Well-trained and dedicated riders | Cost effective delivery
                    platform | Smart phone equipped fleet | Live tracking | 24*7
                    operational support{" "}
                  </p>
                </div>
              </li>
            </ul>
            <div>
              <br />
            </div>
            {/* <p>There are some big shifts taking place in the field of construction equipment mathematics. Starting with the integration of mathematics devices in vehicles right from manufacturers, the standardization and integration of mathematics data across various business functions, the future of mathematics.</p> */}
            <div className="service-details-list">
              <img
                src="assets/img/post-1.jpg"
                style={{ height: "100% !important" }}
                alt="img"
              />
              <div>
                <h3>Why choose Reliance Delivery Services?</h3>
                <p>Everything your business needs is already here!</p>
                <ul className="service-icon-list">
                  <li>
                    <i className="las la-hand-point-right" />
                    Speed and Efficiency
                  </li>
                  <p>
                    Our express delivery service ensures that your packages
                    reach their destination swiftly, minimizing downtime and
                    keeping your operations running smoothly.
                  </p>
                  <li>
                    <i className="las la-hand-point-right" />
                    Reliability
                  </li>
                  <p>
                    With a robust logistics network and advanced tracking
                    systems, we guarantee timely and secure delivery of your
                    important documents and parcels.
                  </p>
                  <li>
                    <i className="las la-hand-point-right" />
                    Customized Solutions
                  </li>
                  <p>
                    We tailor our delivery services to meet the unique needs of
                    your business, whether it's same-day delivery, overnight
                    shipping, or scheduled deliveries.
                  </p>
                  <li>
                    <i className="las la-hand-point-right" />
                    Dedicated Support
                  </li>
                  <p>
                    Our customer support team is available 24/7 to assist you
                    with any queries or special requests, ensuring a seamless
                    delivery experience.
                  </p>
                </ul>
              </div>
            </div>
            {/* <div><br/></div> */}
            {/* <ul class="service-icon-list">
                      <li><i class="las la-object-ungroup"></i>Speed and Efficiency</li><p>Our express delivery service ensures that your packages reach their destination swiftly, minimizing downtime and keeping your operations running smoothly.</p>
                      <li><i class="las la-hand-point-right"></i>Reliability</li><p>With a robust logistics network and advanced tracking systems, we guarantee timely and secure delivery of your important documents and parcels.</p>
                      <li><i class="las la-hand-point-right"></i>Customized Solutions</li><p>We tailor our delivery services to meet the unique needs of your business, whether it's same-day delivery, overnight shipping, or scheduled deliveries.</p>
                      <li><i class="las la-hand-point-right"></i>Dedicated Support</li><p>Our customer support team is available 24/7 to assist you with any queries or special requests, ensuring a seamless delivery experience.</p>                                
                  </ul> */}
            {/* <ul class="service-features">

                      <li>
                          <div><i class="las la-object-ungroup"></i></div>
                          <div>
                              <h3>Visual Designing</h3>
                              <p>Everything your taxi business needs is already here!</p>
                          </div>
                      </li>


                      </ul> */}
            <section className="project-section grid bg-grey padding">
              <div className="container">
                <h4>
                  Key advantages on choosing Reliance Express Delivery for
                  Business
                </h4>
                <div className="row">
                  <div className="col-lg-4 col-md-6 padding-15">
                    <div className="project-item">
                      <div className="project-content">
                        <h3>
                          <a href="#">Free from Payroll</a>
                        </h3>
                        <a href="#" className="read-more">
                          No need for riders on fixed payroll.
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 padding-15">
                    <div className="project-item">
                      <div className="project-content">
                        <h3>
                          <a href="#">No distractions</a>
                        </h3>
                        <a href="#" className="read-more">
                          Focus on your core competence.
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 padding-15">
                    <div className="project-item">
                      <div className="project-content">
                        <h3>
                          <a href="#">No HR headaches</a>
                        </h3>
                        <a href="#" className="read-more">
                          No hiring and retention pains.
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 padding-15">
                    <div className="project-item">
                      <div className="project-content">
                        <h3>
                          <a href="#">Optimized Delivery</a>
                        </h3>
                        <a href="#" className="read-more">
                          No worry for volume peaks &amp; troughs.
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 padding-15">
                    <div className="project-item">
                      <div className="project-content">
                        <h3>
                          <a href="#">Track anytime</a>
                        </h3>
                        <a href="#" className="read-more">
                          Real time updates and order tracking.
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/.service-details*/}
  <Footer/>
</>

  )
}

export default Forbusiness