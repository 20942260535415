import React from 'react'

function Header() {
  return (
    <>
      {/*[if lt IE 8]>
    <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="http://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
      <![endif]*/}
    <div className="site-preloader">
      <div className="spinner" />
    </div>
    {/*site preloader*/}
    <header className="header-wrapper">
      <div className="topbar-wrapper">
        <div className="container">
          <div className="topbar-inner">
            <div className="topbar-left">Express Delivery Service in UAE!</div>
            <div className="topbar-right">
              <ul>
                <li>
                  Hotline:<a href="tel:+971 423 23 221"> +971 423 23 221 </a> , <a href="tel:++971 56 227 6992 "> +971 56 227 6992  </a>
                </li>
                {/* <li class="topbar-social">
                            <a href="https://reliancegcc.com/img/facebook_icon.svg">Fb</a>
                            <a href="https://twitter.com/reliancegcc">X</a>
                            <a href="https://www.instagram.com/reliancegcc/">Inst</a>
                            <a href="https://www.linkedin.com/company/reliancegcc">in</a>
                        </li> */}
                {/* <div class="social-links"> */}
                <li className="topbar-social" />
                <a href="https://www.facebook.com/reliancegcc">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="https://twitter.com/reliancegcc">
                  <i className="fab fa-x" />
                </a>
                <a href="https://www.instagram.com/reliancegcc/">
                  <i className="fab fa-instagram" />
                </a>
                <a href="https://www.linkedin.com/company/reliancegcc">
                  <i className="fab fa-linkedin-in" />
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* /.topbar-wrapper */}
      <div className="navigation-wrapper">
        <div className="container">
          <div className="navigation-inner">
            <div className="site-logo">
              <a href="/">
                <img src="assets/img/logo.png" alt="Redias" />
              </a>
            </div>
            <nav className="navigation-menu">
              <ul className="main-menu">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="#">Services</a>
                  <ul>
                    <li>
                      <a href="/services_for_business">For Business</a>
                    </li>
                    <li>
                      <a href="/services_for_individual">For Individual</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Solutions</a>
                  <ul>
                    <li>
                      <a href="/solutions_for_ecommerce">For Ecommerce</a>
                    </li>
                    <li>
                      <a href="/solutions_for_pharmacy">For Pharmacy</a>
                    </li>
                    <li>
                      <a href="/solutions_for_grocery">For Grocery</a>
                    </li>
                    <li>
                      <a href="/solutions_for_restaurants">For Restaurants</a>
                    </li>
                    <li>
                      <a href="/solutions_for_offices">For Offices</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Vacancies</a>
                  <ul>
                    <li>
                      <a href="/vacancies">Open Vacancies</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Explore</a>
                  <ul>
                    <li>
                      <a href="/about">About us</a>
                    </li>
                    <li>
                      <a href="/blogs">Our Blog</a>
                    </li>
                    <li>
                      <a href="/clients">Our Clients</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/contacts">Contact</a>
                </li>
              </ul>
            </nav>
            <div className="header-right">
              <ul>
                {/* <li class="d-none d-lg-block">
                            <div class="search-icon dl-search-icon">
                                <i class="las la-search"></i>
                            </div>
                        </li> */}
                {/* <li class="login"><a href="#"><i class="las la-user"></i> Login</a></li> */}
                <li className="d-none d-lg-block">
                  <a href="/get_a_quote" className="default-btn">
                    Get a Quote
                  </a>
                </li>
                <li className="d-lg-none">
                  <div className="mobile-menu-icon">
                    <div className="burger-menu">
                      <div className="line-menu line-half first-line" />
                      <div className="line-menu" />
                      <div className="line-menu line-half last-line" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    {/*/.main-header*/}
    <div id="popup-search-box">
      <div className="box-inner-wrap d-flex align-items-center">
        <form id="form" action="#" method="get" role="search">
          <input
            id="popup-search"
            type="text"
            name="s"
            placeholder="Type keywords here..."
          />
          <button id="popup-search-button" type="submit" name="submit">
            <i className="las la-search" />
          </button>
        </form>
        <div className="search-close">
          <i className="las la-times" />
        </div>
      </div>
    </div>
    {/*/.popupsearch-box*/}
    <div id="searchbox-overlay" />
    </>
  )
}

export default Header