import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

function Clients() {
  return (
    <>
    <Helmet>
       <title>Clients - Reliance Delivery Services | Trusted Delivery Partners and Success Stories</title>
       <meta name="description" content="Discover our valued clients and their success stories with Reliance GCC. We are proud to partner with leading businesses across various industries, providing reliable and efficient delivery services." />
       <meta name="keywords" content="Reliance delivery services clients, delivery service partners, success stories, reliable delivery, client testimonials, trusted delivery partners, logistics solutions" />     
     </Helmet>
    <Header/>
  <section className="page-header page-header-clients">
    <div className="page-header-shape" />
    <div className="container">
      <div className="page-header-info">
        <h4>RDS</h4>
        <h2>
          <span>Clients!</span>
        </h2>
        {/* <p>Reliance Delivery Services - MOVE. LOVE. CARE.</p> */}
      </div>
    </div>
  </section>
  {/*/.page-header*/}
  <section className="team-section padding">
    <div className="container">
      <div className="section-heading text-center mb-40">
        <h4 className="sub-heading">Our Clients</h4>
        <h2>
          Successful Companies
          <br />
          Partnered with us!
        </h2>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/7.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/3.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/9.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/10.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/8.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/16.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/25.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/26.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/1.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/2.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/4.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/5.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/6.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/11.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/12.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/13.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/14.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/15.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/17.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/18.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/19.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/20.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/23.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/24.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/21.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding marhinclients">
          <div className="team-item">
            <div className="team-item-inner">
              <img
                className="team-thumb"
                src="assets/img/logos/22.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer/>
</>

  )
}

export default Clients